<meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
<template>
            <router-view></router-view>
</template>

<script>
export default {
      name: 'App'
}
</script>

<style lang="scss">
body{
      margin: 0;
      font-size: 14px;
}

.pageHeadContainer{
      width: 100%; padding-top: 20px; padding-bottom: 20px; background-color: #f7f7f7; border-radius: 10px; margin: 0px 0px 20px 0px;
}

/*BEGIN 页面布局相关*/
.divPlateContainer{
  width: 99.8%; border-radius: 10px;
  border: 1px solid #efefef;
  padding: 10px 0px 20px 0px;
  align-items: center; font-size: 14px;
  display: flex; flex-direction: row; flex-wrap: wrap;
  margin-bottom: 20px;
}

.divPlateSearch{
  width: 99.8%;
  border-bottom: 1px solid #efefef;
  padding: 0px 0px 10px 0px;
  align-items: center; font-size: 14px;
  display: flex; flex-direction: row; flex-wrap: wrap;
  margin-bottom: 20px;
}

.divPlateInnerContainer{
  width: 100%;
  margin-left: 20px; margin-right: 20px;
  display: flex; flex-direction: row; flex-wrap: wrap;
}
.divPlateTitle{
  font-weight: bolder; background-color: #efefef; line-height: 40px; width: 100%;
}
.divPlateTitle span{
  margin-left: 20px;
}
.divCell{
  width: 50%; height: 50px; line-height: 50px; align-items: center; margin-top: 5px;
}
.inputCell{
  width: 40% !important;
}
.divCellV2{
  width: 50%; height: 50px; line-height: 50px; align-items: center; margin-top: 5px; display: flex; flex-direction: row;
}
.divCellTitle{
  width: 80px; text-align: right;
}
/*END 页面布局相关*/

/*BEGIN 应用于内页头的搜索框*/
.divInnerTop{
  width: 100%; border-radius: 10px; background-color: #f8f9fb;
  padding: 20px 0px 20px 0px;
  align-items: center; font-size: 14px;
  display: flex; flex-direction: row; flex-wrap: wrap;
}
.divInnerTop div{
  align-items: center; margin-top: 2px; margin-bottom: 2px;
}
/*END 应用于内页头的搜索框*/

/*BEGIN elementPlus修改*/
.el-table{        /*修改el-table表头颜色*/
  --el-table-header-bg-color: #f8f9fb !important;
}
.el-dialog{
  margin-top: 12% !important;
}
/*BEGIN 修改el-table鼠标经过颜色*/
.el-table tbody tr:hover>td {
  background: #f1f4ff !important;
}
.el-table__body .el-table__row.hover-row td{
  background-color: #f1f4ff !important;
}
/*END 修改el-table鼠标经过颜色*/

/* END elementPlus修改*/

</style>
